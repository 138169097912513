ion-avatar {
  background-color: #222d3a;
  --border-radius: 10px;
  width: 64px;
  height: 64px;
  border: 1px solid #1c1c1c !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  overflow: hidden;
}

ion-avatar::slotted {
  --border-radius: 10px;
  width: 24px;
  height: 24px;
  border: 2px solid #1c1c1c;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  overflow: hidden;
}

ion-avatar.favorite {
  --border-radius: 10px;
  width: 24px;
  height: 24px;
  border: 2px solid #1c1c1c;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  overflow: hidden;
}

ion-avatar.big-avatar {
  width: 92px;
  height: 92px;
  margin: 4px;
}

ion-avatar img {
  border-radius: 10px;
}
