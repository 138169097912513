@import 'button.css';
@import 'benefits.css';
@import 'inputs.css';
@import 'card.css';
@import 'home.css';
@import 'market.css';
@import 'regalos.css';
@import 'popover.css';
@import 'less900.css';
@import 'over900.css';
@import 'onboarding.css';
@import 'avatar.css';

body {
  background-color: #fcfcfc;
}

.hide {
  display: none !important;
}

.lines {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
}
.padding {
  padding: 12px;
}

.avatar {
  display: inline-block;
}
.sub-label {
  font-size: small;
}
.horizontal-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  padding-right: 50px;
  padding-left: 30px;
}

.hide-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.section-header {
  font-weight: 700;
  font-family: Raleway;
}

.right {
  text-align: right;
}
.raleway {
  font-family: Raleway !important;
}
.rubix {
  font-family: Rubik !important;
}

.primary-color {
  color: #577979;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9;
  top: -2px;
  background-color: white;
}

.tooltip {
  /* background-color:rgba(0, 0, 0, 0.05) ; */
  padding: 12px;
  color: rgb(116, 116, 116);
  border-radius: 12px;
  border: 1px rgba(116, 116, 116, 0.25) solid;
}

.menu-item {
  font-weight: bold;
  padding: 4px;
}

.social-icon {
  display: inline-block;
  padding: 12px;
  font-size: x-large;
}
.inline {
  display: inline-block;
}
.bottom {
  display: flex;
  align-items: end;
  width: 100%;
}
.chatBox {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding-bottom: 30px;
  background-color: white;
  min-height: 80px;
  border-top: 1px solid #ccc;
  border-bottom: 0px solid #ccc;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  contain: size style layout;
}

.partner-slide {
  min-height: 200px;
  background-color: #eee;
}

ion-header {
  --ion-background-color: white;
}

.mw-center {
  text-align: center;
}

.mw-div-center {
  margin-right: auto;
  margin-left: auto;
}

.mw-vertical-align {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.mw-bold {
  font-weight: bold;
}

.mw-subdue {
  opacity: 75%;
}

.mw-nopadding-top {
  padding-top: 0px;
}

.mw-nopadding-bottom {
  padding-bottom: 0px;
}

.mw-text-space {
  margin-top: 0.5em;
  margin-bottom: 0.9em;
  padding: 0.5em;
}

.mw-full-width {
  width: 100%;
}

.mw-width-99 {
  width: 99%;
}

.mw-width-60 {
  width: 60%;
}

.mw-width-50 {
  width: 50%;
}

.mw-width-40 {
  width: 40%;
}

.mw-width-25 {
  width: 25%;
}

.mw-calender {
  color: black;
}

.mw-float-right {
  float: right;
}

.mw-float-left {
  float: left;
}

ion-alert.mw-alert {
  --backdrop-opacity: 0.1;
}

.SkipVIPModal::part(content) {
  --height: 150px;
  height: 150px;
}

.AddPet {
  --background: white;
  --background-hover: white;
}

.SuccessImg {
  opacity: 65%;
  width: 300px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.desktop-tabs {
  text-align: left;
  background-color: #1c1c1c;
}

.desktop-tab {
  cursor: pointer;
  z-index: 9;
  color: white;
  display: inline-block;
  font-weight: bold;
  padding: 10px;
  font-size: 18px;
  vertical-align: middle;
}
.desktop-tabs-logo {
  display: inline-block;
  margin: 16px;
  width: 120px;
  vertical-align: middle;
}

.desktop-tabs-icon {
  margin: 8px;
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: 16px;
  color: white;
  font-size: 44px;
  text-align: center;
  vertical-align: middle;
  float: right;
  padding-top: 10px;
}
.desktop-tabs-budget {
  margin: 8px;
  height: 60px;
  background-size: cover;
  border-radius: 16px;
  color: white;
  font-size: 34px;
  text-align: center;
  vertical-align: middle;
  float: right;
  padding-top: 12px;
}

ion-item {
  --border-top-width: 0px;
  --border-end-width: 0px;
  --border-bottom-width: 1px;
  --border-start-width: 0px;
  --ion-item-background: #fcfcfc;
  --inner-border-width: 0px;
  --border-color: black;
}
.list-ios ion-item {
  background-color: #fcfcfc;
  --background: #fcfcfc !important;
}
ion-list {
  --background: #fcfcfc !important;
}

ion-tab-bar {
  padding-top: 8px;
}
ion-tab-bar,
ion-tab-button {
  background-color: white;
  font-size: 12px;
}
ion-tab-bar i {
  font-size: 20px;
  padding-bottom: 3px;
}

ion-tab-bar i.bi-flower3 {
  font-size: 26px;
  margin-top: -2px;
  margin-bottom: -6px;
}

h1 {
  font-weight: 500;
}

h3 {
  font-weight: 400;
  opacity: 80%;
}

h4 {
  opacity: 80%;
}

h5 {
  opacity: 80%;
}

ion-toolbar {
  background: none !important;
  --background: none !important;
}

ion-header {
  background-color: #fcfcfc !important;
  --background-color: #fcfcfc !important;
}

ion-select {
  box-shadow: none !important;
}
.ion-no-lines {
  --border-width: 0px !important;
}
.card-slide {
  margin: 10px;
  width: calc(100% - 20px) !important;
}
.card-slides-container {
  width: calc(100% -24px);
  overflow: visible;
}
.card-slides {
  width: calc(100% - 48px);
  overflow: visible !important;
}
.card-slides-wrapper {
  width: calc(100% + 48px);
  margin-left: -24px;
  overflow: hidden;
}
.swiper-pagination-bullet {
  border-radius: 2px;
  background-color: black;
  width: 10px;
  margin-left: 8px !important;
  margin-right: 8px !important;
  height: 10px;
}

.slide-card {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-bottom: 3px solid black;
  border-right: 2px solid black;
  min-height: 520px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
  padding-bottom: 20px;
}

ion-tab-bar,
ion-tab-button {
  background-color: #fcfcfc !important;
}
ion-list {
  --background: #fcfcfc !important;
  background-color: #fcfcfc !important;
}

ion-content {
  --background: #fcfcfc !important;
  background-color: #fcfcfc !important;
}
ion-page {
  --background: #fcfcfc !important;
  background-color: #fcfcfc !important;
}
.display-linebreak {
  white-space: pre-line;
}
