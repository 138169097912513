ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --border-radius: 0px;
  border-radius: 0px;
}

ion-popover::part(backdrop) {
  background-color: rgba(255, 255, 255, 0);
}

@media only screen and (min-width: 900px) {
  ion-popover::part(content) {
    border: solid 1px black;
    width: 680px;
    min-height: 100px;
    padding: 10px;
  }
}

@media only screen and (max-width: 900px) {
  ion-popover::part(content) {
    border: solid 1px black;
    width: calc(100% - 32px);
    min-height: 100px;
    padding: 10px;
  }
}

.select-interface-option {
  --border-radius: 10px;
  --border-width: 0px;
  --background: white;
  --color: black;
  --background: white;
}

.item-radio-checked {
  --border-radius: 10px;
  --border-width: 1px;
  --background: #f4f4f4 !important;
  --color: black;
}

.custom-modal {
  --height: 75vh !important;
  align-items: end;
  --border-radius: 16px 16px 0px 0px;
}

.regalos-tag-popover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9;
}

.regalos-tag-popover-content {
  position: fixed;
  border: solid 1px black;
  width: 280px !important;
  padding: 14px;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: '0 5px 10px 0 rgba(0, 0, 0, 0.8)';
}
