/* Windows over than 900px */
@media only screen and (min-width: 900px) {
  ion-content.main-content {
    --ion-background-color: #fcfcfc;
  }
  ion-list {
    --ion-background-color: #fcfcfc;
  }

  .mw-back-button {
    background-color: #fcfcfc;
    position: fixed;
    border-radius: 6px;
    top: 80px;
    left: calc(50% - 430px);
    z-index: 100;
    font-size: 35px;
    height: 35px;
    padding: 0px;
  }

  .main-container {
    /* background-color: rgba(252, 252, 252, 1) !important; */
    background-color: #fcfcfc;
    width: 900px;
    /* width: 60%; */
    min-width: 500px;
    margin-left: calc(50% - 450px);
    margin-right: auto;
    margin-left: auto;
    margin-top: -2px;
    border-left: 2px black solid;
    border-right: 2px black solid;

    padding-bottom: 50px;
    min-height: 100%;
  }

  .chatBox {
    position: absolute !important;
  }

  ion-content::part(background),
  ion-content {
    background-color: #fcfcfc;
  }

  .main-container:first-child {
    /* padding-top: 30px; */
  }

  .mobile-tabs {
    display: none;
  }
  .desktop-tabs {
    position: sticky;
    top: 0px;
    left: 0px;
  }

  #mobile-header {
    display: none;
  }
  #full-header {
  }
}
