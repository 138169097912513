.address-item {
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease-in-out;
}

.address-item:hover {
  background-color: #000000 !important;
}

.address-searchbar {
  --background: transparent;
  --box-shadow: none;
}

.address-searchbar .searchbar-input,
.searchbar-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.address-searchbar .searchbar-search-icon {
  display: none !important;
}
