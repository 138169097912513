ion-select.ion-color-light,
ion-datetime-button.ion-color-light,
ion-button.ion-color-light,
ion-button.button-solid:hover {
  transition: all 0.1s ease;
  border: 0px solid black;
  background-color: rgba(255, 255, 255, 0) !important;
  color: black !important;
  --background-hover-opacity: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
  outline: none;
}
ion-select.ion-color-dark,
ion-datetime-button.ion-color-dark,
ion-button.ion-color-dark:hover {
  transition: all 0.1s ease;
  border: 1px solid black;
  background-color: white !important;
  color: black !important;
  --background-hover-opacity: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
  border-bottom: 3px solid black;
  border-right: 2px solid black;
  outline: none;
}
ion-select.ion-color-light,
ion-datetime-button.ion-color-light,
ion-button.ion-color-light {
  --ion-color-base: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 8px;
  border: 0px solid #1c1c1c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
  font-size: 18px;
  height: 40px;
  overflow: hidden;
  min-width: 100px;
  text-transform: capitalize;
  width: -moz-fit-content;
  width: fit-content;
}

ion-select.ion-color-tertiary,
ion-datetime-button.ion-color-tertiary,
ion-button.ion-color-tertiary {
  --ion-color-base: rgba(255, 255, 255, 0) !important;
  --ion-color-contrast: black !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 8px !important;
  border: 1px solid #1c1c1c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  color: #1c1c1c;
  font-size: 18px !important;
  height: 40px;
  min-width: 100px;
  overflow: hidden;
  text-transform: capitalize;
  width: -moz-fit-content;
  width: fit-content;
}
ion-select.ion-color-dark,
ion-datetime-button.ion-color-dark,
ion-button.ion-color-dark {
  font-size: 18px;
  --ion-color-base: white !important;
  --ion-color-contrast: black !important;
  text-transform: capitalize;
  background-color: white !important;
  --background-color: white !important;
  border: 1px solid black;
  border-bottom: 3px solid black;
  border-right: 2px solid black;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden;
  height: 40px;
  min-width: 100px;
  width: -moz-fit-content;
  width: fit-content;
}
ion-select.ion-color-dark,
ion-datetime-button.ion-color-dark,
ion-button.ion-color-dark:active {
  --ion-color-base: #ccc !important;
  --ion-color-contrast: black !important;
  border-bottom: 3px solid #1c1c1c !important;
  border-right: 2px solid black !important;
}

ion-datetime-button::part(native) {
  width: 100%;
  background-color: white;
}

ion-datetime-button.date-active::part(native) {
  background-color: grey;
  color: black;
}

/* HeaderSubmenu button */
.mw-close-button {
  position: fixed;
  top: 70px;
  left: 20px;
  height: 26px;
  z-index: 9;
}
.mw-close-button .icon {
  padding: 0px !important;
  padding-bottom: 2px;
  font-size: 28px;
}

.mw-close-button-background {
  background-color: white;
  height: 25px;
  width: 28px;
  margin-top: -30px;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.leave-room-for-menu {
  padding-top: 34px;
}

@media only screen and (max-width: 900px) {
  .mw-close-button {
    position: fixed;
    top: 75px;
    left: 20px;
  }
  ion-button.button-block {
    margin-left: calc(50% - 250px);
    margin-right: auto;
    margin-left: auto;
    /* max-width: 500px !important; */
    min-width: 260px;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 900px) {
  .mw-close-button {
    position: fixed;
    top: 70px;
    left: calc(50% - 430px);
  }
}

/* media tag for screen larger then of 900 */
@media screen and (min-width: 900px) {
  ion-button.button-block {
    margin-left: calc(50% - 250px);
    margin-right: auto;
    margin-left: auto;
    /* max-width: 500px !important; */
    /* max-width: 260px; */
    margin-bottom: 10px;
  }
}

ion-toggle {
  height: 26px;
  width: 65px;

  padding: 12px;
  border: 1px solid #fff !important;
  --background: #ddd;
  --background-checked: rgb(157, 204, 191);

  --handle-background: #bfbfbf;
  --handle-background-checked: #505050;

  --handle-width: 24px;
  --handle-height: 24px;
  --handle-max-height: auto;
  --handle-spacing: 0px;
  --handle-border: 1px solid #fff;
  --border-radius: 8px;

  --handle-border-radius: 8px;
  --handle-box-shadow: none;

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}

.backButton:hover {
  text-decoration: underline;
}

.backButton {
  border: none !important;
  box-shadow: none !important;
}

ion-button::part(native) {
  border-radius: 0px !important;
  --border-radius: 0px !important;
}

.button-small {
  font-size: 14px !important;
  height: 30px !important;
  min-width: 100px !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.login-segment-button-left {
  --border-radius: 10px !important;
  border-radius: 10px 0px 0px 10px !important;
  border: 1px solid #1c1c1c !important;
  font-size: 18px !important;
  padding: 10px !important;
  font-weight: 500;
  overflow: hidden !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.login-segment-button-right {
  --border-radius: 10px !important;
  border-radius: 0px 10px 10px 0px !important;
  border: 1px solid #1c1c1c !important;
  font-size: 18px !important;
  padding: 10px !important;
  font-weight: 500;
  overflow: hidden !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.login-segment-button-left-selected {
  --border-radius: 10px !important;
  border-radius: 10px 0px 0px 10px !important;
  border: 1px solid #1c1c1c !important;
  font-size: 18px !important;
  padding: 10px !important;
  font-weight: 500;
  overflow: hidden !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #1c1c1c;
  color: white;
}
.login-segment-button-right-selected {
  --border-radius: 10px !important;
  border-radius: 0px 10px 10px 0px !important;
  border: 1px solid #1c1c1c !important;
  font-size: 18px !important;
  padding: 10px !important;
  font-weight: 500;
  overflow: hidden !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #1c1c1c;
  color: white;
}
