.regalos-header {
  background-color: rgba(158, 204, 191, 1);
  width: 100%;
  height: 100px;
  font-weight: bold;
  color: black;
}

.regalos-cards {
  margin-top: -60px;
  overflow: auto;
}

.regalos-send-modal {
  --height: 100vh !important;
  align-items: end;
  --min-width: 900px !important;
}
