/* Windows less than 900px */
@media only screen and (max-width: 900px) {
  .main-container {
    /* background-color: rgba(252, 252, 252, 1) !important; */
    background-color: #fcfcfc;
    max-width: 900px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: -2px;

    padding-bottom: 50px;
    min-height: 100%;
  }

  ion-content::part(background),
  ion-content {
    background-color: #fcfcfc;
  }

  .mw-back-button {
    position: fixed;
    top: 80px;
    left: 20px;
    z-index: 100;
    font-size: 35px;
  }
  .mobile-tabs {
    border-top: #1c1c1c solid 1px;
    background-color: #fcfcfc !important;
  }
  .desktop-tabs {
    display: none;
  }

  #mobile-header {
  }

  #full-header {
    display: none;
  }

  /* ion-header {
    visibility: collapse;
    height: 0px;
  } */
}
