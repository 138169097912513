.benefits-header {
  background-color: #c7adff;
  width: 100%;
  height: 100px;
  font-weight: bold;
  padding-left: 20px;
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
