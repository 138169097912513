.home-balance {
  font-size: 60px;
}
.announcements-header {
  background-color: rgb(28, 28, 28);
  width: 100%;
  height: 100px;
  font-weight: 500;
  font-family: Rubik;
  color: white;
}
.announcement-cards {
  margin-top: -40px;
  overflow: auto;
  padding-bottom: 0px;
}

.activity-header {
  font-weight: 700;
  font-family: Raleway;
}

.big-number-font {
  color: black;
  text-shadow:
    1px 1px 0px #ffffff,
    2px 2px 4px #00000068;
}

/* Make font look plastic */
/* text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 #000,
    1px 1px 0 #000; */
