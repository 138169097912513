.mw-input-lbl {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 3px;
  display: flex;
}

.mw-chk-lbl {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 3px;
  width: auto;
  float: left;
}

.mw-chk {
  margin-right: 5px;
  width: auto;
  float: right;
}

.mw-input {
  border: solid 1px black;

  border-radius: 8px;
  height: 40px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.mw-search {
  border-radius: 18px;
  height: 40px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
  padding: 0px;
}

input::placeholder {
  color: lightgrey;
  opacity: 50%;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: lightgrey;
  opacity: 50%;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: lightgrey;
  opacity: 50%;
}
.mw-input-item input {
  height: 14px !important;
  margin: 1px !important;
}
ion-item.mw-select {
  --min-height: 20px;
  --border-bottom-width: 0px;
  border: 1px solid black !important;
  border-bottom: 1px solid black !important;
  margin: 10px !important;
  width: calc(100% - 20px);
  border-radius: 10px;
  font-size: 16px;
  padding: 1px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
}
.mw-select ion-select {
  font-size: small;
  border-radius: 20px;
  text-align: left !important;
  width: 100%;
  height: 8px !important;
  margin: 1px !important;
  background-color: transparent;
}

.mw-code-input {
  width: 40px;
  height: 35px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 5px;
  font-size: 18px;
  text-align: center;
  padding: 0px !important;
}

.action-sheet-group {
  border: 1px solid black;
  border-bottom: 0px;
}
.action-sheet-group:first-child {
  border-radius: 10px 10px 0px 0px;
}

.action-sheet-selected {
  border: 1px solid rgba(182, 184, 186, 1) !important;
  margin: 10px;
  border-radius: 10px;
  width: calc(100% - 20px) !important;
  background-color: rgba(236, 236, 236, 1) !important;
  padding-left: -10px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

ion-item.mw-input-item {
  --min-height: 30px;
  --border-bottom-width: 0px;
  border: solid 1px black !important;

  margin: 10px !important;
  width: calc(100% - 20px);
  border-radius: 10px;
  font-size: 16px;
  padding: 1px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.error_text {
  color: red;
  padding: 2px 6px 6px 6px;
  font-size: small;
  font-style: italic;
}

ion-input,
ion-datetime-button,
ion-select {
  height: 40px;
}

ion-textarea {
  /* margin-bottom: 36px !important; */
}

ion-range::part(tick) {
  background: #5e7a72;
}

ion-range::part(tick-active) {
  background: #5e7a72;
}

ion-range::part(knob) {
  background: #5e7a72;
}

ion-range::part(pin) {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  transform: scale(1.01);
  border-color: #5e7a72;
  background: #5e7a72;
  color: #fff;

  top: -20px;

  min-width: 28px;
  height: 28px;
  transition:
    transform 120ms ease,
    background 120ms ease;
}

ion-range::part(pin)::before {
  /* content: none; */
}

ion-range::part(knob) {
  background: #5e7a72;
}

ion-range::part(bar) {
  background: #5e7a72;
}

ion-range::part(bar-active) {
  background: #5e7a72;
}

input[type='tel'] {
  background-color: white;
  color: black;
  border: solid 1px black;
  border-radius: 8px;
  padding: 10px;
  padding-left: 10px;
}

input[type='tel']::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
  opacity: 1; /* Firefox */
  border: solid 0px black;
  border-radius: 5px;
}

input[type='tel']:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
  border: solid 0px black;
  border-radius: 5px;
}

input[type='tel']::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray;
  border: solid 0px black;
  border-radius: 5px;
}

input[type='tel']:-webkit-autofill,
input[type='tel']:-webkit-autofill:focus {
  background-color: white !important;
  color: black !important;
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

.sc-ion-input-md-h,
.sc-ion-input-ios-h {
  --padding-start: 10px;
}

@supports (-webkit-touch-callout: none) {
  /* ios device specific */
  select,
  select.form-control {
    border-radius: 10px;
  }
}

ion-checkbox {
  --border-color: #000;
  --border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

ion-select {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

ion-input {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #fcfcfc;
}
.ion-item-input-money {
  border: 0px solid black !important;
  height: 38px;
  width: calc(100% - 20px) !important;
  border-radius: 6px;
  text-align: right;
  width: min-content;
  display: inline-block;
  padding-right: 5px;
}

input {
  background-color: #fcfcfc;
}

.react-datetime-picker {
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  padding: 6px;
  width: 100%;
}
.react-datetime-picker__wrapper {
  border: 0px solid black !important;
}
.react-calendar {
}
.input-calendar {
  border: 1px solid black !important;
  background-color: #fcfcfc !important;
  z-index: 999;
}
div[contenteditable] {
  outline: none;
}
