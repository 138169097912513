.favorites-header {
  background-color: #02989d;
  width: 100%;
  height: 100px;
  font-weight: bold;
  padding-left: 20px;
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.favorite-cards {
  margin-top: -60px;
  overflow: hidden;
}
