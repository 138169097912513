.react-joyride__tooltip {
  border: 1px solid black;
  border-bottom: 3px solid black;
  border-right: 2px solid black;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.__floater__arrow span svg {
  stroke: black;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.6);
  }
}

.beacon {
  animation: pulse 1s ease-in-out infinite;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
