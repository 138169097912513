.card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 1px solid black;
  border-bottom: 3px solid black;
  border-right: 2px solid black;
  padding: 5px 5px 5px 5px;
}
.card-header-image {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  background-position: 'center center';
}
.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 0px 3px 0px 3px;
  font-size: small;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: normal;
}

.card-tag {
  font-size: 12px;
  background-color: rgba(244, 244, 244, 1);
  padding: 6px;
  border-radius: 10px;
}
/* Make font look plastic */
.plastic {
  color: black;
  text-shadow:
    1px 1px 0px #00000068,
    1px 1px 3px #fff;
}
/* Make font look plastic */
.plastic-3 {
  color: black;
  text-shadow:
    1px 1px 0px #00000068,
    1px 1px 3px #fff;
}
/* Make font look plastic */
.plastic-2 {
  color: black;
  text-shadow:
    1px 1px 0px #00000068,
    1px 1px 2px #fff;
}
/* Make font look plastic */
.plastic-1 {
  color: black;
  text-shadow:
    1px 1px 0px #00000068,
    1px 1px 1px #fff;
}
#card-number-copy {
  height: 24px;
  width: 24px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArUlEQVR4nO3WsQ3CMBBA0VsAiszDACCqFGxAYyT2pU4YIg18FOEiEo7sO8w1+ElpIkdfuVwRkSYBOAJ38iYgSC3ASJlnvC61wqXOwCPGr25heZ+tF0cRNsdZLJI1HJ8Ji28eVIukDQO7lfikGqshXDSNpBaetVFbSE7qsKnUwjn/PWpgo/wRWDNow328d/giPgB7bfgGdOKBT/ObnoCtd1jNGh759SKluCxSI45enXaQYXJyysEAAAAASUVORK5CYII=');
}
#card-number-copy-success {
  display: none;
  height: 24px;
  width: 24px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArUlEQVR4nO3WsQ3CMBBA0VsAiszDACCqFGxAYyT2pU4YIg18FOEiEo7sO8w1+ElpIkdfuVwRkSYBOAJ38iYgSC3ASJlnvC61wqXOwCPGr25heZ+tF0cRNsdZLJI1HJ8Ji28eVIukDQO7lfikGqshXDSNpBaetVFbSE7qsKnUwjn/PWpgo/wRWDNow328d/giPgB7bfgGdOKBT/ObnoCtd1jNGh759SKluCxSI45enXaQYXJyysEAAAAASUVORK5CYII=');

  background-size: 100%;
}
